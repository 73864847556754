import { StateCreator } from 'zustand'

export type RevisionSlice = {
  documentUrl: string
  setDocumentUrl: (documentUrl: string) => void
  documentViewerOpen: boolean
  setDocumentViewerOpen: (documentViewerOpen: boolean) => void
}

export const createRevisionSlice: StateCreator<RevisionSlice> = (set) => ({
  metadata: null,
  documentUrl: '',
  documentViewerOpen: false,
  setDocumentViewerOpen: (documentViewerOpen: boolean) => {
    set({
      documentViewerOpen,
    })
  },
  setDocumentUrl: (documentUrl) => {
    set({
      documentUrl,
    })
  },
})
