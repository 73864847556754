import React, { ReactNode, useEffect } from 'react'
import { AXIOS_INSTANCE } from './CustomAxiosInstance'
import { useFeatureConfig } from '@vori/dashboard-hooks/useFeatureConfig'
import { FeatureFlags } from '@vori/dashboard-constants'

/**
 * API authentication provider
 *
 * This is responsible for ensuring the correct headers get passed to the API
 * for authentication, feature enablement, and whatever else we come up with
 * in the future.
 *
 * TODO: Actually use this for authentication and get rid of getHTTPHeaders().
 *
 * @note This was adapted from https://github.com/orval-labs/orval/blob/b23257b281f931a8a8a13cae43fb0f59cacf2763/samples/react-app-with-swr/basic/src/auth.context.tsx.
 */
const ApiAuthProvider = ({ children }: { children: ReactNode }) => {
  const paginatePromotions = useFeatureConfig(
    FeatureFlags.WEB_PROMOTIONS_PAGINATE,
  )

  useEffect(() => {
    const interceptor = AXIOS_INSTANCE.interceptors.request.use((config) => {
      config.headers['x-vori-enable-pagination-beta'] = paginatePromotions
      return config
    })

    return () => {
      AXIOS_INSTANCE.interceptors.request.eject(interceptor)
    }
  }, [paginatePromotions])

  return <>{children}</>
}

export { ApiAuthProvider }
