import * as Sentry from '@sentry/react'

import { ENV_PROD } from '@vori/dashboard-env'

export function captureException(callback: (scope: Sentry.Scope) => Error) {
  if (!ENV_PROD) {
    return
  }

  try {
    Sentry.withScope((scope) => {
      Sentry.captureException(callback(scope))
    })
  } catch (err) {
    console.error('Failed to capture exception with Sentry', err)
  }
}
