import { getAuth, onAuthStateChanged } from 'firebase/auth'
import React from 'react'

import { captureException } from '@vori/dashboard-integrations/Sentry/utils'
import { GATED_FEATURE_IDs } from '@vori/dashboard-constants'
import { getGlobalHTTPHeadersFromSessionStorage } from '@vori/dashboard-hooks/useGlobalHTTPHeaders'
import { getFirebaseApp } from '@vori/dashboard-utils'

import { useAppGetCurrentUserLazyQuery } from '@vori/gql-dashboard'

import { setLocalStorageRecord } from '@vori/dashboard-utils/localStorage'

import {
  CurrentUserDispatchContextValue,
  CurrentUserStateContextValue,
} from './types'

import { USER_DATA_CACHE_KEY } from './constants'
import { useCurrentUserReducer } from './reducer'

const CurrentUserStateContext = React.createContext<
  CurrentUserStateContextValue | undefined
>(undefined)

const CurrentUserDispatchContext = React.createContext<
  CurrentUserDispatchContextValue | undefined
>(undefined)

/**
 * Returns the current `value` for the parent `<CurrentUserStateContext>`,
 * if any.
 */
export function useCurrentUserState(): CurrentUserStateContextValue {
  const value = React.useContext(CurrentUserStateContext)

  if (value === undefined) {
    throw new Error(
      'The `useCurrentUserStateContext` hook must be used within `<CurrentUserProvider>`',
    )
  }

  return value
}

/**
 * Returns the current `value` for the parent `<CurrentUserDispatchContext>`,
 * if any.
 */
export function useCurrentUserDispatch(): CurrentUserDispatchContextValue {
  const value = React.useContext(CurrentUserDispatchContext)

  if (value === undefined) {
    throw new Error(
      'The `useCurrentUserDispatchContext` hook must be used within `<CurrentUserProvider>`',
    )
  }

  return value
}

/**
 * A React provider component that exposes the current user state and dispatch
 * function all children components.
 */
export function CurrentUserProvider({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element {
  // TODO (VOR-9295) Put back after long-term solution has been implemented
  // const cachedUserState =
  //   getLocalStorageRecord<CurrentUserData>(USER_DATA_CACHE_KEY)

  const [state, dispatch] = useCurrentUserReducer(null)

  const [getCurrentUserData] = useAppGetCurrentUserLazyQuery({
    variables: { gatedFeatures: GATED_FEATURE_IDs },
  })

  React.useEffect(() => {
    const firebaseApp = getFirebaseApp()

    if (!firebaseApp) {
      return
    }

    const auth = getAuth(firebaseApp)

    const unsubscribeFromAuthListener = onAuthStateChanged(
      auth,
      async (user) => {
        dispatch({ type: 'userV2/authenticationStarted' })

        // If `user` is not defined, then `onAuthStateChanged` was triggered
        // due to the user logging out.
        if (!user) {
          // Remove locally cached data from the user that was just logged out.
          localStorage.removeItem(USER_DATA_CACHE_KEY)
          // Clear user data from memory from the user that was just logged out.
          dispatch({ type: 'userV2/unauthenticated' })
          // Exit early from this effect.
          return
        }

        try {
          // TODO (VOR-9295) Put back after long-term solution has been implemented
          // // If `state.user.data.id` is defined at this point, then this means that
          // // the user was already logged in and we have their data stored locally,
          // // therefore, we don't need to fetch it.
          // const userData = state.user.data.id
          //   ? // Use locally cached user data
          //     state.user.data
          //   : // Or fetch user data
          //     (await getCurrentUserData()).data?.me?.user

          const userData = (await getCurrentUserData()).data?.me?.user

          // If, for whatever reason, we don't have locally cached user data and
          // the request for fetching the user's data doesn't return anything
          // for this user, we clear any data that we might have cached locally
          // and stored in memory which will then trigger a redirect to the login page.
          if (!userData) {
            // Remove locally cached data from the user that encountered an
            // authentication error.
            localStorage.removeItem(USER_DATA_CACHE_KEY)

            // Clear user data from memory from the user that encountered an
            // authentication error.
            dispatch({
              type: 'userV2/authenticationFailed',
              payload: {
                error: new Error('User data not found'),
              },
            })

            // Exit early from this effect.
            return
          }

          // If there is no auth token stored, then the user is logging in, so
          // we store the user's data on localStorage to prevent hitting the API
          // on subsequent visits.
          if (!state.user.authToken) {
            setLocalStorageRecord(USER_DATA_CACHE_KEY, userData)
          }

          // Get the latest authentication token from the currently logged in
          // user, to keep in memory so we can use it in other places, e.g.
          // HTTP headers.
          const authToken = await user.getIdToken()

          // Store the current user's data in memory.
          dispatch({
            type: 'userV2/authenticated',
            payload: {
              authToken,
              data: userData,
              globalHTTPHeaders: getGlobalHTTPHeadersFromSessionStorage(),
            },
          })
        } catch (error) {
          // If the request for fetching the user's data fails, we clear anything
          // that we might have cached locally and stored in memory which will
          // then trigger a redirect to the login page.
          localStorage.removeItem(USER_DATA_CACHE_KEY)

          captureException((scope) => {
            const errorDebugMessage =
              "User authentication failed while trying to fetch the user's data via the GraphQL API (AppGetCurrentUserQuery)"

            scope.captureMessage(errorDebugMessage, 'debug')
            return error instanceof Error ? error : new Error(errorDebugMessage)
          })

          dispatch({
            type: 'userV2/authenticationFailed',
            payload: {
              error:
                error instanceof Error
                  ? error
                  : new Error('Firebase authentication failed'),
            },
          })
        }
      },
      (error) => {
        captureException((scope) => {
          scope.captureMessage(
            "User authentication failed due to changes to the user's sign-in state (onAuthStateChanged).",
            'debug',
          )

          return error
        })

        // If the request for authenticating the user fails, we clear anything
        // that we might have cached locally and stored in memory which will
        // then trigger a redirect to the login page.
        localStorage.removeItem(USER_DATA_CACHE_KEY)
        dispatch({ type: 'userV2/authenticationFailed', payload: { error } })
      },
    )

    return () => {
      // Unsubscribe from listening to user authentication changes when the
      // Provider component unmounts.
      unsubscribeFromAuthListener()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CurrentUserStateContext.Provider value={state}>
      <CurrentUserDispatchContext.Provider value={dispatch}>
        {children}
      </CurrentUserDispatchContext.Provider>
    </CurrentUserStateContext.Provider>
  )
}
