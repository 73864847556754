import { compact } from 'lodash'
import { useId } from '@reach/auto-id'
import { useIsMounted } from '@vori/react-hooks'
import styled, { css } from 'styled-components'
import VisuallyHidden from '@reach/visually-hidden'

import {
  positionDefault,
  positionMatchWidth,
  positionRight,
} from '@reach/popover'

import React, {
  forwardRef,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import {
  ListboxButton,
  ListboxContextValue,
  ListboxInput,
  ListboxInputProps,
  ListboxList,
  ListboxOption,
  ListboxPopover,
} from '@reach/listbox'

import { foundations, TokenFoundationSizeContainer } from '@vori/gourmet-tokens'
import { ChevronDownIcon, ChevronUpIcon } from '@vori/gourmet-icons'

import { Button, ButtonProps, ButtonCoreProps } from '../ButtonNext'
import { Card, CardProps } from '../CardNext'
import { Flex } from '../FlexNext'
import { Spacer } from '../SpacerNext'
import { Text } from '../TextNext'
import HiddenInput from '../HiddenInput'
import List, { ListProps } from '../List'
import Loader from '../Loader'
import MenuItem, { MenuItemProps } from '../MenuItem'

import { toRem } from '../utils'
import { colors, foundation, Size, sizing } from '../tokens'

const triggerStyles = css<ButtonCoreProps>(({ noFocusRing }) => ({
  '&:not([data-icon-only-button]):not([data-icon-button])': {
    justifyContent: 'space-between',
  },

  '&[data-expanded="true"]:not([data-no-focus-ring])': {
    boxShadow: !noFocusRing
      ? `0 0 0 ${toRem(2)} #FFFFFF, 0 0 0 ${toRem(4)} #6038EF`
      : 'none',
  },

  ':focus:not([data-no-focus-ring]), :focus-within:not([data-no-focus-ring])': {
    boxShadow: !noFocusRing
      ? `0 0 0 ${toRem(2)} #FFFFFF, 0 0 0 ${toRem(4)} #6038EF`
      : 'none',
  },
}))

const Container = styled.span<{
  $fullWidth?: boolean
  $renderPopupInline?: boolean
}>`
  ${({ $renderPopupInline }) =>
    $renderPopupInline ? 'position: relative;' : ''}
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};

  [data-reach-listbox-input][data-value='']
    [data-gourmet-button][data-variant='default']:not(
      [data-gourmet-filter-menubar-chip]
    ) {
    color: #70707b;
  }

  [data-reach-listbox-button] {
    display: inline-block;
    width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'max-content')};
  }

  [data-gourmet-button],
  [data-reach-listbox-button] button,
  [data-icon-only-button] {
    ${triggerStyles}
  }

  &[aria-invalid='true'] [data-gourmet-button],
  &[aria-invalid='true'] [data-reach-listbox-button] button,
  &[aria-invalid='true'] [data-icon-only-button] {
    border-color: '#FD9AA4';
  }
`

const TriggerButton = styled(Button)<ButtonProps>`
  ${triggerStyles}
`

const StyledListBoxInput = styled(ListboxInput)`
  align-items: stretch;
  display: inline-flex;
  width: 100% !important;
`

const StyledListBoxPopover = styled(ListboxPopover)<{
  $size: CardProps['size']
  $renderPopupInline?: boolean
}>`
  max-width: ${({ $size, position }) =>
    $size != null
      ? sizing.container[$size as Size] ||
        foundations.size[`size.${$size}` as TokenFoundationSizeContainer]
      : position !== positionMatchWidth
        ? 'auto'
        : ''};

  min-width: ${toRem(240)};

  width: ${({ $size, position, $renderPopupInline }) => {
    if ($renderPopupInline) {
      return '100%'
    }

    return $size != null
      ? '100% !important'
      : position !== positionMatchWidth
        ? 'auto !important'
        : ''
  }};

  z-index: 1000;

  ${({ $renderPopupInline }) =>
    $renderPopupInline
      ? `
    top: calc(100% + ${foundation.spacing.small});
    left: 0;
    position: absolute;
  `
      : ''}
`

const StyledListBoxOption = styled(ListboxOption)`
  &[aria-disabled='true'] {
    background-color: ${colors.listbox.option.disabled.backgroundColor};
    pointer-events: none;
  }
`

const StyledCard = styled(Card)`
  overflow: hidden;

  [data-gourmet-search-input] {
    margin: -${foundations.space['space.050']} -${foundations.space[
        'space.075'
      ]}
      ${foundations.space['space.050']};
  }
`

const ListContainer = styled.div<{
  $hasFooter?: boolean
  $hasHeader?: boolean
}>`
  margin-bottom: ${({ $hasFooter }) =>
    !$hasFooter ? `-${foundations.space['space.050']}` : '0'};
  margin-left: -${foundations.space['space.125']};
  margin-right: -${foundations.space['space.125']};
  margin-top: ${({ $hasHeader }) =>
    !$hasHeader ? `-${foundations.space['space.050']}` : '0'};
  width: calc(100% + (${foundations.space['space.125']} * 2));

  && ${MenuItem} {
    border-radius: 0;
  }
`

type ListBoxOptionProps = React.HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean
  label?: string
  menuItemProps?: MenuItemProps
  value: string
}

type ListBoxPosition = 'default' | 'right' | 'matchWidth'

type ListBoxProps<T = void> = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  'onChange'
> & {
  canToggleOptions?: boolean
  disabled?: boolean
  /**
   * @deprecated Prevents the default trigger button from going into its "active" state
   * when a value is currently selected.
   */
  disableTriggerActiveState?: boolean
  fullWidth?: boolean
  getOptionDisabled?: (option: T) => boolean
  getOptionKey?: (option: T) => string
  getOptionLabel?: (option: T) => string
  getLabelForTrigger?: (value: string | null) => string
  getOptionMenuItemProps?: (option: T) => MenuItemProps
  getOptionValue?: (option: T) => string
  /**
   * Provides text for screen readers that is visually hidden.
   * It is the logical opposite of the aria-hidden attribute.
   */
  label: string
  listProps?: ListProps<T>
  listBoxInputProps?: Partial<
    Omit<ListboxInputProps, 'as' | 'children' | 'onChange' | 'ref' | 'value'>
  >
  name?: React.AllHTMLAttributes<HTMLInputElement>['name']
  multipleValues?: boolean
  onChange?: ListboxInputProps['onChange']
  options: T[]
  position?: ListBoxPosition
  popupProps?: Omit<CardProps, 'size'> & {
    ref?: React.Ref<HTMLDivElement>
    size?: CardProps['size'] | Size
  }
  renderFooter?: () => React.ReactNode
  renderHeader?: () => React.ReactNode
  renderOption?: (option: T, isSelected: boolean) => React.ReactNode
  /**
   * Displays the popup alongside the trigger, instead of a React portal. This
   * is useful when rendering within a dialog displayed as a modal.
   */
  renderPopupInline?: boolean
  renderTrigger?: (
    selection: string | null,
    isExpanded: boolean,
    computedLabel: string,
  ) => React.ReactNode
  showEmpty?: boolean
  showLoader?: boolean
  triggerProps?: ButtonProps
  value?: ListboxInputProps['value']
}

const defaultProps: Partial<ListBoxProps> = {
  canToggleOptions: false,
  className: '',
  disabled: false,
  fullWidth: false,
  listBoxInputProps: {},
  listProps: {},
  multipleValues: false,
  options: [],
  popupProps: {},
  position: 'default',
  renderPopupInline: false,
  showEmpty: false,
  showLoader: false,
  triggerProps: {},
}

const ListBoxOption = styled(
  forwardRef<HTMLDivElement, ListBoxOptionProps>(function InnerListBoxOption(
    { children, disabled, menuItemProps, value, ...props }: ListBoxOptionProps,
    ref: React.Ref<HTMLDivElement>,
  ) {
    return (
      <StyledListBoxOption
        {...props}
        {...(menuItemProps != null && menuItemProps)}
        disabled={disabled}
        forwardedAs={MenuItem}
        ref={ref}
        value={value}
      >
        {children}
      </StyledListBoxOption>
    )
  }),
)``

const StyledListBox = styled(
  forwardRef<HTMLSpanElement, ListBoxProps>(function ListBox<T>(
    {
      canToggleOptions,
      disabled,
      disableTriggerActiveState,
      fullWidth,
      getLabelForTrigger,
      getOptionDisabled = () => false,
      getOptionKey: originalGetOptionKey,
      getOptionLabel = (option) => String(option),
      getOptionMenuItemProps,
      getOptionValue = (option) => String(option),
      label,
      listBoxInputProps,
      listProps,
      multipleValues,
      onChange,
      onKeyDown: originalOnKeyDown,
      options,
      popupProps,
      position,
      renderFooter,
      renderHeader,
      renderOption: originalRenderOption,
      renderPopupInline,
      renderTrigger,
      showEmpty,
      showLoader,
      triggerProps,
      value: controlledValue,
      ...props
    }: ListBoxProps<T>,
    ref: React.Ref<HTMLSpanElement>,
  ) {
    const isMounted = useIsMounted()
    const listBoxButtonRef = useRef<HTMLSpanElement | null>(null)

    const [value, setValue] = useState<string>(
      listBoxInputProps?.defaultValue || '',
    )

    const labelId = `listbox--${useId()}`
    const [listRef, setListRef] = useState<HTMLDivElement | null>(null)

    const getOptionKey = useCallback<(option: T) => string>(
      (option) =>
        originalGetOptionKey != null
          ? originalGetOptionKey(option)
          : `select-option-key-${getOptionValue(option)}`,
      [getOptionValue, originalGetOptionKey],
    )

    const renderOption = useCallback<(option: T) => React.ReactNode>(
      (option) =>
        originalRenderOption != null ? (
          originalRenderOption(
            option,
            (controlledValue || value)
              .split(',')
              .includes(getOptionValue(option)),
          )
        ) : (
          <Text size="text-sm">{getOptionLabel(option)}</Text>
        ),
      [
        controlledValue,
        getOptionLabel,
        getOptionValue,
        originalRenderOption,
        value,
      ],
    )

    const defaultRenderTrigger = useCallback(
      (isExpanded, value, valueLabel) => (
        <TriggerButton
          {...triggerProps}
          data-expanded={isExpanded}
          data-gourmet-listbox-button=""
          data-value={value}
          fullWidth={fullWidth}
          rightIcon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
        >
          {getLabelForTrigger != null
            ? getLabelForTrigger(value || null)
            : valueLabel || label}
        </TriggerButton>
      ),
      [fullWidth, getLabelForTrigger, label, triggerProps],
    )

    const handleOnChange = useCallback(
      (newValue) => {
        if (controlledValue == null) {
          if (multipleValues) {
            setValue((prevValues) => {
              const newValuesArray = compact(prevValues.split(','))

              if (
                canToggleOptions &&
                newValuesArray.findIndex(
                  (newValuesArrayItem) => newValuesArrayItem === newValue,
                ) !== -1
              ) {
                return Array.from(
                  new Set(
                    newValuesArray.filter(
                      (prevValue) => prevValue !== newValue,
                    ),
                  ),
                ).join(',')
              }

              return Array.from(new Set(newValuesArray.concat(newValue))).join(
                ',',
              )
            })
          } else {
            setValue((prevValue) =>
              canToggleOptions
                ? prevValue === newValue
                  ? ''
                  : newValue
                : newValue,
            )
          }
        }

        if (onChange != null) {
          onChange(newValue)
        }

        /**
         * This unfortunate hack is required because we already hacked the
         * <Listbox> component from reach-ui to support multiple values.
         * When the `multipleValues` prop is `true`, we want the popup list
         * of values to remain opened so we give the user the option to select
         * another value. The problem is reach-ui does not give us access to
         * the component's internal state so we can't programmatically
         * open/close the popup.
         *
         * Thus, this hack will detect when the user selects a value and if
         * the `multipleValues` prop is `true`, then we trigger a `mousedown`
         * event on the next repaint so the popup list appears to remain opened.
         */
        if (multipleValues && listBoxButtonRef.current !== null) {
          requestAnimationFrame(() => {
            if (isMounted && listBoxButtonRef.current !== null) {
              listBoxButtonRef.current.dispatchEvent(
                new Event('mousedown', {
                  bubbles: true,
                  cancelable: true,
                  composed: true,
                }),
              )
            }
          })
        }
      },
      [canToggleOptions, controlledValue, isMounted, multipleValues, onChange],
    )

    const onKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement | HTMLInputElement>) => {
        if (listRef != null) {
          window.requestAnimationFrame(() => {
            const element: HTMLElement | null = listRef.querySelector(
              '[aria-selected="true"]',
            )

            if (element != null) {
              element.scrollIntoView({
                block: 'nearest',
              })
            }
          })
        }

        if (originalOnKeyDown != null) {
          originalOnKeyDown(event)
        }
      },
      [listRef, originalOnKeyDown],
    )

    /**
     * Override isSelected logic from <ListboxOption> to support multiple
     * selected values.
     *
     * @see {@link https://github.com/reach/reach-ui/blob/c13f68b1af8544cc61971157c9fd7d07733828d6/packages/listbox/src/index.tsx#L1074}
     */
    useLayoutEffect(() => {
      if (listRef != null && multipleValues) {
        const values = controlledValue || value

        listRef
          .querySelectorAll('[data-current]')
          .forEach(($option) => $option.removeAttribute('data-current'))

        values.split(',').forEach((singleValue) => {
          listRef
            .querySelector(`[data-value="${singleValue}"]`)
            ?.setAttribute('data-current', '')
        })
      }
    }, [controlledValue, listRef, multipleValues, value])

    return (
      <Container
        {...props}
        data-gourmet-listbox=""
        $fullWidth={fullWidth || false}
        $renderPopupInline={renderPopupInline}
        ref={ref}
      >
        <HiddenInput
          name={props.name}
          value={controlledValue != null ? controlledValue : value}
        />
        <VisuallyHidden id={labelId}>{label}</VisuallyHidden>
        <StyledListBoxInput
          {...listBoxInputProps}
          aria-labelledby={labelId}
          onChange={handleOnChange}
          onKeyDown={onKeyDown}
          value={controlledValue != null ? controlledValue : value}
        >
          {({ isExpanded, value, valueLabel }: ListboxContextValue) => (
            <>
              <ListboxButton ref={listBoxButtonRef}>
                {renderTrigger != null
                  ? renderTrigger(
                      value,
                      isExpanded,
                      getLabelForTrigger?.(value || null) ||
                        valueLabel ||
                        label,
                    )
                  : defaultRenderTrigger(isExpanded, value, valueLabel)}
              </ListboxButton>
              <StyledListBoxPopover
                portal={!renderPopupInline}
                $renderPopupInline={renderPopupInline}
                $size={popupProps?.size}
                position={
                  position === 'default'
                    ? positionDefault
                    : position === 'right'
                      ? positionRight
                      : position === 'matchWidth'
                        ? positionMatchWidth
                        : positionDefault
                }
              >
                <ListboxList as="div">
                  {!renderPopupInline && <Spacer size="space.075" />}
                  <StyledCard
                    {...popupProps}
                    size={popupProps?.size as CardProps['size']}
                  >
                    {renderHeader != null && renderHeader()}
                    {showEmpty && (
                      <Flex direction="column" center flex={1} fullWidth>
                        <Spacer />
                        <Text align="center" size="text-sm" variant="secondary">
                          No results...
                        </Text>
                        <Spacer />
                      </Flex>
                    )}
                    {showLoader && (
                      <Flex direction="column" center flex={1} fullWidth>
                        <Spacer />
                        <Loader size="medium" />
                      </Flex>
                    )}
                    {options.length > 0 && (
                      <ListContainer
                        $hasFooter={renderFooter != null}
                        $hasHeader={renderHeader != null}
                      >
                        <List {...listProps} ref={setListRef}>
                          {listProps?.virtualize
                            ? null
                            : options.map((option) => (
                                <ListBoxOption
                                  disabled={
                                    disabled || getOptionDisabled(option)
                                  }
                                  label={getOptionLabel(option)}
                                  menuItemProps={
                                    getOptionMenuItemProps != null
                                      ? getOptionMenuItemProps(option)
                                      : {}
                                  }
                                  key={getOptionKey(option)}
                                  value={getOptionValue(option)}
                                >
                                  {renderOption(option)}
                                </ListBoxOption>
                              ))}
                        </List>
                      </ListContainer>
                    )}
                    {renderFooter != null && renderFooter()}
                  </StyledCard>
                  <Spacer size="space.075" />
                </ListboxList>
              </StyledListBoxPopover>
            </>
          )}
        </StyledListBoxInput>
      </Container>
    )
  }),
)`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'inherit')};
  opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};

  [data-gourmet-button],
  [data-reach-listbox-button] button,
  [data-icon-only-button] {
    ${({ disabled }) =>
      disabled
        ? `background-color: ${foundations.color['color.gray-50']};`
        : ''};
  }
`

StyledListBox.displayName = 'ListBox'
StyledListBox.defaultProps = defaultProps

export type { ListBoxOptionProps, ListBoxProps, ListBoxPosition }
export { defaultProps as ListBoxDefaultProps, ListBoxOption }

/**
 * Need to re-cast due to the forwardRef return value.
 * @see {@link https://stackoverflow.com/a/58473012}
 */
export default StyledListBox as <T>(
  props: ListBoxProps<T> & { ref?: React.Ref<HTMLSpanElement> },
) => React.ReactElement
