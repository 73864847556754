import { StoreApi } from 'zustand'
import { ApplicationDataSlice } from './ApplicationDataStore'
import { ErrorManagementSlice } from './ErrorManagementStore'
import { GlobalModalSlice } from './GlobalModalStore'
import { ProductsSlice } from './ProductStore'
import { RevisionSlice } from './RevisionStore'
import { UserSlice } from './UserStore'

export enum SearchHistoryReturnOrder {
  'LIFO' = 'last-in-first-out',
  'FIFO' = 'first-in-first-out',
}

export type GlobalStoreType = {
  appData: ApplicationDataSlice
  globalModal: GlobalModalSlice
  products: ProductsSlice
  user: UserSlice
  errorManagement: ErrorManagementSlice
  revisions: RevisionSlice
}

export type GlobalStoreKeys = keyof GlobalStoreType

/**
 * This utility function helps with the concatenation of the store slices.
 * As all of the slices will follow the namespace implementation ( user, produts etc )
 * this will ensure that all slices are focused on their specific functionality. It is the definiteion of a helper function
 * @param sliceName: An automatically typed string version of the appropriate name space ( ref: GlobalStoreKeys )
 * @param setfn: The provided zustang set function
 * @param getfn: The provided zustang get function
 * @param store: A reference to the global namespaced store
 * @returns
 */
export const sliceArgumentsCreator = <
  GlobalStoreKeyGeneric extends GlobalStoreType[keyof GlobalStoreType],
>(
  sliceName: GlobalStoreKeys,
  setfn: (updater: (state: GlobalStoreType) => GlobalStoreType) => void,
  getfn: () => GlobalStoreType,
  store: StoreApi<GlobalStoreType>,
): [
  (partial: Partial<GlobalStoreKeyGeneric>) => void,
  () => GlobalStoreKeyGeneric,
  StoreApi<GlobalStoreKeyGeneric>,
] => {
  // return all 3 items needed by the create_____Slice function [set, get, store]
  return [
    (partial: Partial<GlobalStoreKeyGeneric>) => {
      setfn((state) => ({
        ...state,
        [sliceName]: {
          ...state[sliceName],
          ...partial,
        },
      }))
    },
    () => getfn()[sliceName] as GlobalStoreKeyGeneric,
    store as unknown as StoreApi<GlobalStoreKeyGeneric>, // this is here to ensure that "store" is properly cased as the global store type
  ]
}
