import React from 'react'
import { StoreProductTypeaheadSearchResultDto } from '@vori/dashboard-rest-next/schemas'
import { Badge, LinkNext, TextNext } from '@vori/gourmet-components'
import { Button } from 'gourmet/web-components/src/ButtonNext'
import styled from 'styled-components'
import { foundations } from '@vori/gourmet-tokens'
import { compact } from 'lodash'

type DataProp = StoreProductTypeaheadSearchResultDto | null

const DetailContainer = styled.div`
  padding: 5px;
  color: ${foundations.color['color.gray-500']};
  .header {
    span {
      font-weight: 500;
    }
    .subhead {
      font-size: 0.8rem;
      margin-top: 5px;
    }
  }

  .content-body {
    margin-top: 1.4rem;
  }

  .content-split-item {
    font-size: 0.9rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0.8rem;
    span:nth-child(2) {
      text-align: right;
      color: ${foundations.color['color.gray-800']};
    }
  }
`

const ProductSeachDetailView = ({
  data,
  ctaHandler,
}: {
  data: DataProp
  ctaHandler?: () => void
}) => {
  if (data === null) {
    return data
  }
  const handleViewProductClicked = () => {
    if (ctaHandler) {
      ctaHandler()
    }
  }

  return (
    <DetailContainer>
      <div className="header">
        <TextNext size="text-md">{data.name}</TextNext>
        <div className="subhead">
          {compact([data.brand, data.barcode, data.unit_volume]).join(
            ' \u2022 ',
          )}
        </div>
      </div>
      <div className="content-body">
        <div className="content-split-item">
          <span>Brand</span>
          <span>{data.brand || ''}</span>
        </div>
        <div className="content-split-item">
          <span>Department</span>
          <span>{data.department.name}</span>
        </div>
        <div className="content-split-item">
          <span>Price</span>
          <span>${parseFloat(data.base_retail_price || '').toFixed(2)}/ea</span>
        </div>
        <div className="content-split-item">
          <span>Status</span>
          <span>
            <Badge
              variant={data.active ? 'positive' : 'negative'}
              label={data.active ? 'Active' : 'Inactive'}
            >
              {data.active ? 'Active' : 'Inactive'}
            </Badge>
          </span>
        </div>
        <LinkNext
          style={{
            textDecoration: 'none',
            width: '100%',
          }}
          onClick={handleViewProductClicked}
          to={`/retail/products/${data.id}/update`}
        >
          <Button
            size="xLarge"
            variant="default"
            style={{
              marginTop: '1rem',
              borderColor: foundations.color['color.gray-200'],
              color: foundations.color['color.gray-800'],
              width: '100%',
            }}
          >
            View Product
          </Button>
        </LinkNext>
      </div>
    </DetailContainer>
  )
}

export default ProductSeachDetailView
