import { base } from '@vori/gourmet-components'
import { foundations } from '@vori/gourmet-tokens'
import styled from 'styled-components'

// Container for the product search component
export const ProductSearchContainer = styled.div`
  --containerHeight: 50vh;
  --headerHeight: 40px;
  --footerHeight: 40px;
  --highlightColor: ${base.colors.darkPurple};

  overflow: hidden;

  [cmdk-root] {
    display: flex;
    flex-direction: column;
    min-height: var(--containerHeight);
    max-height: var(--containerHeight);
    height: var(--containerHeight);
    outline: none;
    border-radius: inherit;
  }
`

// Header of the product search
const ProductSearchHeaderProps = {
  display: 'grid',
  gridTemplateColumns: '25px 1fr',
  height: '50px',
  minHeight: '50px',
  padding: '16px',
  paddingLeft: '5px',
  borderTopRightRadius: 'inherit',
  borderTopLeftRadius: 'inherit',
  borderBottom: `1px solid ${foundations.color['color.gray-100']}`,
  span: {
    width: '16px',
    height: '17px',
  },
  svg: {
    opacity: '0.5',
  },
  input: {
    border: 'none',
    width: '100%',
    'font-size': '14px',
    'border-radius': '0.5rem',
    'padding-left': '5px',
    color: foundations.color['color.gray-800'],
  },
}

export const ProductSearchHeader = styled.div(ProductSearchHeaderProps)

export const ProductSearchResultSubHead = styled.div({
  padding: '6px 12px',
  height: '28px',
  fontSize: '12px',
  color: foundations.color['color.gray-500'],
})

// Wrapper for the product search results section
export const ProductSearchResult = styled.div({
  flexGrow: 1,
  overflow: 'hidden',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(50%, 1fr))',
  'max-height': 'calc(var(--containerHeight) - var(--headerHeight))',

  '.product-search-results-left, .product-search-results-right': {
    overflowY: 'scroll',
    maxHeight:
      'calc(var(--containerHeight) - (var(--headerHeight) + var(--footerHeight)))',
  },

  '.product-search-result-item': {
    padding: '5px',
    fontSize: '0.8rem',

    h6: {
      margin: 0,
      lineHeight: '1rem',
      fontWeight: '500',
    },

    '.product-search-result-item-content': {
      padding: '10px 8px',
    },

    '&:hover > .product-search-result-item-content, &[data-selected="true"] > .product-search-result-item-content':
      {
        borderRadius: '6px',
        backgroundColor: foundations.color['color.gray-100'],
      },

    '*, span': {
      pointerEvents: 'none',
      minHeight: '20px',
    },

    span: {
      display: 'inline-block',
      color: foundations.color['color.gray-500'],
    },
  },

  '.product-search-results-right': {
    padding: '0 0.2rem',
    borderLeft: `1px solid ${foundations.color['color.gray-100']}`,
  },

  '[cmdk-item]': {
    cursor: 'pointer',
    padding: '0 8px',
    minHeight: '60px',
    maxHeight: '60px',
  },
})

// Footer of the product search
const ProductSearchFooterProps = {
  minHeight: 'var(--footerHeight)',
  maxHeight: 'var(--footerHeight)',
  borderTop: `1px solid ${foundations.color['color.gray-100']}`,
  padding: '10px 0.5rem',
  display: 'grid',
  gap: '1.8rem',
  'grid-template-columns': 'repeat(3, minmax(min-content, max-content))',
  color: foundations.color['color.gray-500'],
}

export const ProductSearchFooter = styled.div(ProductSearchFooterProps)

export const ProductSearchFooterIcons = styled.span({
  padding: '0 0.4rem 0.1rem 0.4rem',
  'margin-right': '2px',
  // border: `1px solid ${base.colors.light4}`,
  border: `1px solid ${foundations.color['color.gray-50']}`,
  backgroundColor: 'rgba(150, 150, 150, 0.15)',
  textAlign: 'center',
  'border-radius': '0.3rem',
})

export const SearchItem = styled.div({
  display: 'grid',
  'grid-template-columns': '20px 1fr',
  gap: '8px',
  padding: '0.63rem 0.5rem',
  color: foundations.color['color.gray-800'],
  cursor: 'pointer',
  'line-height': '16px',
  'border-radius': '0.5rem',
  ':hover': {
    'background-color': base.colors.light2,
  },
  svg: {
    color: base.colors.dark3,
  },
})

export const BorderedTrigger = styled.div({
  display: 'grid',
  gridTemplateColumns: '25px 1fr 1fr',
  minHeight: '1.5rem',
  maxHeight: '1.9rem',
  border: `1px solid ${base.colors.dark2}`,
  borderRadius: '0.5rem',
  color: base.colors.dark2,
  overflow: 'hidden',
  width: '100%',
  lineHeight: '21px',
  padding: '5px',
  paddingTop: '3px',
  marginTop: '-5px',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: `${base.colors.dark1}`,
    color: base.colors.light4,
  },
  svg: {
    marginTop: '1px',
  },
  div: {
    'text-align': 'right',
    'padding-right': '0.5rem',
  },
})
