import { ClockRewindIcon, Trash01Icon } from '@vori/gourmet-icons'
import React from 'react'
import { useGlobalStore } from 'web/dashboard/src/state-management/store'
import styled from 'styled-components'
import { base } from '@vori/gourmet-components'
import {
  ProductSearchResultSubHead,
  SearchItem,
} from './ProductSearchStyledComponents'

const RecentSerachContainer = styled.div({
  color: base.colors.dark2,
  'max-height': 'calc(var(--containerHeight) - var(--headerHeight))',
})

const ScrollArea = styled.div({
  'overflow-y': 'scroll',
  'max-height': 'calc(var(--containerHeight) - 80px)',
})

type IncomingProps = {
  redoPastSearch: null | ((value: string) => void)
}
const RecentProductSearches = ({ redoPastSearch }: IncomingProps) => {
  const getSearchHistory = useGlobalStore(
    (state) => state.products.getSearchHistory,
  )

  const clearSearchHistory = useGlobalStore(
    (state) => state.products.clearSearchHistory,
  )

  const handleRedoSearch = (event: React.MouseEvent<HTMLDivElement>) => {
    const trueTarget = event.target as HTMLElement
    if (redoPastSearch && trueTarget.dataset.value) {
      redoPastSearch(trueTarget.dataset.value)
    }
  }

  if (!getSearchHistory().length) {
    return (
      <RecentSerachContainer>
        <ProductSearchResultSubHead>
          No recent searches
        </ProductSearchResultSubHead>
      </RecentSerachContainer>
    )
  }
  return (
    <RecentSerachContainer>
      <ProductSearchResultSubHead>Recent</ProductSearchResultSubHead>
      <ScrollArea>
        {getSearchHistory().map((searchTerm, idx) => (
          <SearchItem
            key={`${searchTerm}-${idx}`}
            data-value={searchTerm}
            onClick={handleRedoSearch}
          >
            <ClockRewindIcon /> {searchTerm}
          </SearchItem>
        ))}
        <SearchItem onClick={clearSearchHistory}>
          <Trash01Icon /> Clear search history
        </SearchItem>
      </ScrollArea>
    </RecentSerachContainer>
  )
}

export default RecentProductSearches
