import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { useBodyScrollLock } from '@vori/react-hooks'
import { usePreviousValue } from '@vori/react-hooks'

import { colors, spacing } from '../tokens'
import { composeRefs } from '../utils'
import { DialogContext, DialogContextValue } from './DialogContext'
import { FocusTrap } from '../FocusTrap'
import { PropsFromHook } from './types'

function styles(): CSSObject {
  return {
    backgroundColor: 'transparent',
    borderWidth: 0,
    padding: 0,

    '&::backdrop': {
      backdropFilter: 'blur(8px)',
      backgroundColor: colors.overlay,
      padding: spacing.modal.overlay,
    },
    '&[data-disable-backdrop-filter]::backdrop': {
      backdropFilter: 'none',
    },
  }
}

const StyledDialog = styled.dialog(styles)

type BaseProps = Omit<
  React.DialogHTMLAttributes<HTMLDialogElement>,
  'aria-label' | 'aria-labelledby'
> &
  PropsFromHook & {
    /**
     * Disables the the underlying <FocusTrap> component.
     */
    disableFocusTrap?: boolean
    /**
     * Disables auto focus functionality provided by the underlying <FocusTrap>
     * component.
     */
    disableAutoFocus?: boolean
    /**
     * If `true` the contents of the dialog will be rendered the first time you
     * display the dialog via the `show` method. The contents will remain on the
     * dialog from that point forward, even when closing the dialog.
     */
    renderContentsOnShow?: boolean
    /**
     * If `true` the contents of the dialog will only be rendered when you
     * display the dialog via the `show` method. The contents will be removed
     * from the dialog when closing it.
     */
    removeContentsOnClose?: boolean
    /**
     * If `true` the dark background of the dailog display will not have a blur
     * applied to it. When set to `false` (which is the default state) it will retain the default blur value
     */
    disableBackgroundBlur?: boolean
  }

type Props =
  | (BaseProps & { 'aria-label': string; 'aria-labelledby'?: string })
  | (BaseProps & { 'aria-label'?: string; 'aria-labelledby': string })

/**
 * A dialog is a window overlaid on either the primary window or another
 * dialog window. They are most often used to prompt the user to enter or
 * respond to information. Dialogs contain their tab sequence. That is,
 * `Tab` and `Shift + Tab` do not move focus outside the dialog.
 *
 * Because of the imperative nature of the HTML `<dialog>` element API,
 * you'll have to use the `useDialog()` hook to access the API via the `ref`
 * property returned by the hook.
 *
 * Remember to provide an accessible name for the dialog, which can be done with
 * the `aria-label` or `aria-labelledby` attribute(s).
 *
 * @example
 * function Modal() {
 *   const { ref, triggerProps, ...dialog } = useDialog({ asModal: true })
 *
 *   return (
 *     <>
 *      <Button
 *        {...triggerProps}
 *        onClick={() => {
 *          dialog.open()
 *        }}
 *      >
 *        Open
 *      </Button>
 *      <Dialog {...dialog} aria-labelledby="title-123" ref={ref}>
 *        <Card column>
 *          <Text id="title-123">This is a modal dialog</Text>
 *          <Spacer />
 *          <Flex fullWidth justifyContent="space-between">
 *            <Button>Some Button</Button>
 *            <Spacer inline />
 *            <Button
 *              onClick={() => {
 *                dialog.close()
 *              }}
 *            >
 *              Close
 *            </Button>
 *          </Flex>
 *        </Card>
 *      </Dialog>
 *     </>
 *   )
 * }
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/}
 * @see {@link https://w3c.github.io/aria/#dialog}
 */
const Dialog = React.forwardRef<HTMLDialogElement, Props>(function Dialog(
  {
    children,
    close,
    disableAutoFocus,
    id,
    isModal,
    isOpen,
    removeContentsOnClose,
    renderContentsOnShow,
    disableBackgroundBlur = false,
    show,
    ...props
  }: Props,
  ref,
) {
  const contextValue = React.useMemo<DialogContextValue>(
    () => ({ close, id, isModal, isOpen, show }),
    [close, id, isModal, isOpen, show],
  )

  const prevIsOpen = usePreviousValue(isOpen)

  const [shouldRenderContents, setShouldRenderContents] = React.useState(
    !removeContentsOnClose && !renderContentsOnShow,
  )

  useBodyScrollLock({ isActive: isModal && isOpen })

  React.useEffect(() => {
    if (prevIsOpen !== undefined && prevIsOpen !== isOpen) {
      setShouldRenderContents(() =>
        !isOpen && removeContentsOnClose ? false : true,
      )
    }
  }, [isOpen, prevIsOpen, removeContentsOnClose])

  return (
    <DialogContext.Provider value={contextValue}>
      <FocusTrap autoFocus={!disableAutoFocus} disabled={!isOpen}>
        {(focusTrapRef, focusTrapProps) => (
          <StyledDialog
            {...focusTrapProps}
            data-gourmet-dialog=""
            {...(disableBackgroundBlur && {
              'data-disable-backdrop-filter': 'true',
            })}
            id={id}
            ref={composeRefs<HTMLDialogElement>([ref, focusTrapRef])}
            {...props}
          >
            {shouldRenderContents ? children : null}
          </StyledDialog>
        )}
      </FocusTrap>
    </DialogContext.Provider>
  )
})

Dialog.displayName = 'Dialog'
Dialog.defaultProps = {}

export { Dialog }
export type { Props as DialogProps }
