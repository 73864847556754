import { StateCreator } from 'zustand'

const MAX_ALLOWED_TOKEN_FETCHES_DURING_INTERVAL = 5

export type ApplicationDataSlice = {
  courierToken: string | null
  courierTokenFetchCount: number
  updateCourierToken: (tokenValue?: string | null) => void
  incrementCourierTokenFetchCount: () => void
  resetCourierTokenFetchCount: () => void
  isFetchNewCourierTokenAllowed: () => boolean
}

export const createApplicationDataSlice: StateCreator<ApplicationDataSlice> = (
  set,
  get,
) => ({
  courierToken: null,
  courierTokenFetchCount: 0,
  incrementCourierTokenFetchCount: () => {
    set({
      courierTokenFetchCount: get().courierTokenFetchCount + 1,
    })
  },
  resetCourierTokenFetchCount: () => {
    set({
      courierTokenFetchCount: 0,
    })
  },
  updateCourierToken: (tokenValue = null) => {
    set({
      courierToken: tokenValue,
    })
  },
  isFetchNewCourierTokenAllowed: () => {
    return (
      get().courierTokenFetchCount < MAX_ALLOWED_TOKEN_FETCHES_DURING_INTERVAL
    )
  },
})
