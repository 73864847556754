import { CSSObject } from 'styled-components'
import { foundations } from '@vori/gourmet-tokens'

import { toRem } from '../utils'

export function cardBannerStyles(): CSSObject {
  return {
    backgroundColor: foundations.color['color.gray-25'],
    borderColor: foundations.color['color.gray-300'],
    borderStyle: 'solid',
    borderTopLeftRadius: toRem(8),
    borderTopRightRadius: toRem(8),
    borderWidth: toRem(1),
    margin: [
      toRem(-1),
      `calc((${foundations.space['space.125']} + (${toRem(1)})) * -1)`,
      `${foundations.space['space.100']}`,
    ].join(' '),
    minHeight: toRem(60),
    padding: [
      foundations.space['space.075'],
      foundations.space['space.125'],
    ].join(' '),
    width: `calc(100% + (${foundations.space['space.125']} * 2) + (${toRem(1)} * 2))`,

    '&, [data-gourmet-card-banner-text]': {
      color: foundations.color['color.gray-700'],
    },

    '[data-gourmet-card-banner-button]': {
      color: foundations.color['color.gray-600'],
      marginLeft: 'auto',

      [[
        '&:hover',
        '&[data-hovered]',
        '&:focus',
        '&:focus-within',
        '&[data-focused]',
      ].join(',')]: {
        backgroundColor: foundations.color['color.gray-50'],
        color: foundations.color['color.gray-600'],
      },

      '&:focus, &:focus-within, &[data-focused]': {
        boxShadow: [
          `0 0 0 ${toRem(2)} ${foundations.color['color.gray-25']}`,
          `0 0 0 ${toRem(4)} ${foundations.color['color.gray-100']}`,
        ].join(','),
      },

      '&:disabled': {
        backgroundColor: 'transparent',
        color: foundations.color['color.gray-300'],
      },
    },

    '&[data-variant="error"]': {
      backgroundColor: foundations.color['color.error-25'],
      borderColor: foundations.color['color.error-300'],

      '&, [data-gourmet-card-banner-text]': {
        color: foundations.color['color.error-700'],
      },

      '[data-gourmet-card-banner-button]': {
        color: foundations.color['color.error-600'],

        [[
          '&:hover',
          '&[data-hovered]',
          '&:focus',
          '&:focus-within',
          '&[data-focused]',
        ].join(',')]: {
          backgroundColor: foundations.color['color.error-50'],
          color: foundations.color['color.error-600'],
        },

        '&:focus, &:focus-within, &[data-focused]': {
          boxShadow: [
            `0 0 0 ${toRem(2)} ${foundations.color['color.error-25']}`,
            `0 0 0 ${toRem(4)} ${foundations.color['color.error-100']}`,
          ].join(','),
        },

        '&:disabled': {
          backgroundColor: 'transparent',
          color: foundations.color['color.error-300'],
        },
      },
    },

    '&[data-variant="success"]': {
      backgroundColor: foundations.color['color.success-25'],
      borderColor: foundations.color['color.success-300'],

      '&, [data-gourmet-card-banner-text]': {
        color: foundations.color['color.success-700'],
      },

      '[data-gourmet-card-banner-button]': {
        color: foundations.color['color.success-600'],

        [[
          '&:hover',
          '&[data-hovered]',
          '&:focus',
          '&:focus-within',
          '&[data-focused]',
        ].join(',')]: {
          backgroundColor: foundations.color['color.success-50'],
          color: foundations.color['color.success-600'],
        },

        '&:focus, &:focus-within, &[data-focused]': {
          boxShadow: [
            `0 0 0 ${toRem(2)} ${foundations.color['color.success-25']}`,
            `0 0 0 ${toRem(4)} ${foundations.color['color.success-100']}`,
          ].join(','),
        },

        '&:disabled': {
          backgroundColor: 'transparent',
          color: foundations.color['color.success-300'],
        },
      },
    },

    '&[data-variant="warning"]': {
      backgroundColor: foundations.color['color.warning-25'],
      borderColor: foundations.color['color.warning-300'],

      '&, [data-gourmet-card-banner-text]': {
        color: foundations.color['color.warning-700'],
      },

      '[data-gourmet-card-banner-button]': {
        color: foundations.color['color.warning-600'],

        [[
          '&:hover',
          '&[data-hovered]',
          '&:focus',
          '&:focus-within',
          '&[data-focused]',
        ].join(',')]: {
          backgroundColor: foundations.color['color.warning-50'],
          color: foundations.color['color.warning-600'],
        },

        '&:focus, &:focus-within, &[data-focused]': {
          boxShadow: [
            `0 0 0 ${toRem(2)} ${foundations.color['color.warning-25']}`,
            `0 0 0 ${toRem(4)} ${foundations.color['color.warning-100']}`,
          ].join(','),
        },

        '&:disabled': {
          backgroundColor: 'transparent',
          color: foundations.color['color.warning-300'],
        },
      },
    },

    '& > [data-gourmet-icon]': {
      flexShrink: 0,
    },
  }
}
