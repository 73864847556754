import { StateCreator } from 'zustand'

export type ErrorManagementSlice = {
  hasErrors: boolean
  hasWarnings: boolean
  addNewError: (error: Error) => void
  addAndDisplayNewError: (error: Error) => void
}

enum ErrorLevels {
  ERROR = 'Error',
  WARNING = 'Warning',
}

const buildTitleFromError = (
  error: Error,
  errorLevel: ErrorLevels = ErrorLevels.ERROR,
) => {
  return `Dashboard ${errorLevel}: ${error.name || typeof error}`
}

export const createErrorManagementSlice: StateCreator<ErrorManagementSlice> = (
  set,
  get,
) => ({
  hasErrors: false,
  hasWarnings: false,
  addNewError: (error) => {
    // this is here temporarily to help define how this section will function.
    // it will be removed in the next pr focused exclusively on the error management slice
    console.groupCollapsed(`Error: ${error.name || typeof error}`)
    console.groupCollapsed(buildTitleFromError(error))
    console.error(error)
    console.table(error)
    console.groupEnd()

    set({
      hasErrors: true,
    })
  },
  addAndDisplayNewError: (error) => {
    get().addNewError(error)
    /**
     * now handle the display part of the error handling. This section will be in flux
     * for a shor amount of time as the effort to figure out the best use of this slice
     * will be disccovered during implementation.
     *
     * While there is a plan for the structure of this component it is worth calling out that it can
     * and will change over the coming days
     */
  },
})
