import { SearchSmIcon } from '@vori/gourmet-icons'
import React, { useCallback, useEffect } from 'react'
import ProductSearch from './ProductSearch'
import { useGlobalStore } from 'web/dashboard/src/state-management/store'
import { BorderedTrigger } from './ProductSearchStyledComponents'
import { getPlatforSpecificControlKeyString } from 'web/utils/src/platform'

const ProductSearchTrigger = () => {
  const setAndDisplayContent = useGlobalStore(
    (state) => state.globalModal.setAndDisplayContent,
  )

  const isSearchModalOpen = useGlobalStore((state) => state.globalModal.isOpen)

  const handleTriggerProductSearchModal = useCallback(() => {
    if (isSearchModalOpen) {
      return
    }
    setAndDisplayContent(<ProductSearch />, true, true)
  }, [isSearchModalOpen, setAndDisplayContent])

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'k') {
        event.preventDefault()
        handleTriggerProductSearchModal()
      }
    }

    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleTriggerProductSearchModal])

  return (
    <BorderedTrigger
      key="product-search-trigger"
      onClick={handleTriggerProductSearchModal}
    >
      <SearchSmIcon /> <span>Search for Products</span>
      <div>{getPlatforSpecificControlKeyString()}K</div>
    </BorderedTrigger>
  )
}

export default ProductSearchTrigger
