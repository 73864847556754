import {
  CurrentUser,
  CurrentUserMetadata,
} from '@vori/dashboard-hooks/useCurrentUser'
import { StateCreator } from 'zustand'
import { updateSessionPersistKey } from './store'

export type UserSlice = {
  user: CurrentUser | null
  metadata: CurrentUserMetadata | null
  setUserData: (incomingUserData: CurrentUser | null) => void
  setUserMetadata: (incomingMetadata: CurrentUserMetadata | null) => void
}

export const createUserSlice: StateCreator<UserSlice> = (set) => ({
  metadata: null,
  user: null,
  setUserData: (incomingUserData = null) => {
    set({
      user: incomingUserData,
      metadata: incomingUserData?.metadata || null,
    })

    if (incomingUserData) {
      updateSessionPersistKey(incomingUserData.data.id)
    }
  },
  setUserMetadata: (incomingMetadata = null) => {
    // add an event loop delay to ensure the state is not being updated while the app is being setup
    // this is an affordance because of the useContext update happening at startup
    // eventually all user setup will be handled here and this will not be needed
    // TODO: https://linear.app/vori/issue/VOR-9396
    setTimeout(() => {
      set({
        metadata: incomingMetadata,
      })
    }, 0)
  },
})
