import { searchStoreProducts } from '@vori/dashboard-rest-next/products/products'
import { queryClientInstance } from './networking/queryClient'
import { SearchHistoryReturnOrder } from './storeTypesAndUtils'
import {
  StoreProductTypeaheadSearchPageDto,
  StoreProductTypeaheadSearchResultDto,
} from '@vori/dashboard-rest-next/schemas'
import { StateCreator } from 'zustand'

export type ProductsSlice = {
  loading: boolean
  productStoreError: null | unknown
  canShowRecentSearches: boolean
  searchHistory: string[]
  activeQuery: string
  currentSearchPage: number
  productSearchResults: StoreProductTypeaheadSearchResultDto[]
  activeAbortController: null | AbortController
  searchProduct: (
    storeId: string | string[],
    query: string,
    take?: number,
  ) => void
  clearSearchHistory: () => void
  clearSearchResults: (clearSearchHistory?: boolean) => void
  getSearchHistory: (resultOrder?: SearchHistoryReturnOrder) => string[]
}

export const createProductSlice: StateCreator<ProductsSlice> = (set, get) => ({
  loading: false,
  productStoreError: null,
  canShowRecentSearches: true,
  searchHistory: [],
  activeQuery: '',
  currentSearchPage: 1,
  productSearchResults: [],
  activeAbortController: null,
  getSearchHistory: (resultOrder = SearchHistoryReturnOrder.LIFO) => {
    const currentSearchHistory = [...get().searchHistory]
    switch (resultOrder) {
      case SearchHistoryReturnOrder.LIFO:
        return currentSearchHistory.reverse()

      case SearchHistoryReturnOrder.FIFO:
        return currentSearchHistory

      default:
        return currentSearchHistory
    }
  },
  clearSearchHistory: () => {
    set({ searchHistory: [] })
  },
  clearSearchResults: (clearSavedHistory = false) => {
    if (clearSavedHistory) {
      get().clearSearchHistory()
    }

    set({
      productSearchResults: [],
      canShowRecentSearches: true,
      activeQuery: '',
      currentSearchPage: 1,
    })
  },
  searchProduct: async (storeId, query, take = 20) => {
    if (query.length === 0) {
      get().clearSearchResults()
      return
    }

    const prevController = get().activeAbortController
    set({ canShowRecentSearches: false })
    if (prevController && !get().loading) {
      prevController.abort()
    }

    const currentController = new AbortController()
    const upcomingHistory = new Set(get().searchHistory)
    if (upcomingHistory.has(query)) {
      upcomingHistory.delete(query)
    }
    upcomingHistory.add(query)

    set({
      activeAbortController: currentController,
      loading: true,
      productStoreError: null,
      searchHistory: Array.from(upcomingHistory),
    })

    if (query !== get().activeQuery) {
      set({
        activeQuery: query,
        currentSearchPage: 1,
        productSearchResults: [],
      })
    }

    const storeIdParam = Array.isArray(storeId) ? storeId : [storeId]
    const currentSearchPageIndex = get().currentSearchPage
    const baseQueryKey = ['product-search', query]

    type ReducerInfo = {
      has_next_page: boolean
      next_page_number: number
      cachedData: StoreProductTypeaheadSearchResultDto[]
    }

    const { has_next_page, cachedData, next_page_number } = queryClientInstance
      .getQueriesData<StoreProductTypeaheadSearchPageDto>(baseQueryKey)
      .reduce(
        (output: ReducerInfo, current) => {
          const [_, results] = current
          if (results) {
            const {
              meta: { has_next_page, page },
              data,
            } = results

            output.next_page_number = page + 1
            output.has_next_page = has_next_page
            output.cachedData.push(...data)
          }
          return output
        },
        {
          has_next_page: true,
          next_page_number: currentSearchPageIndex,
          cachedData: [],
        },
      )

    const queryKey = [...baseQueryKey, next_page_number]

    if (cachedData.length && !has_next_page) {
      set({
        loading: false,
        productSearchResults: cachedData,
        activeAbortController: null,
      })
      return
    }

    try {
      const { data, meta } = await queryClientInstance.fetchQuery(
        queryKey,
        () =>
          searchStoreProducts(
            {
              store_ids: storeIdParam,
              query,
              take,
              page: next_page_number,
            },
            { signal: currentController.signal },
          ),
      )

      if (meta.has_next_page) {
        set({ currentSearchPage: next_page_number + 1 })
      }

      set({
        loading: false,
        productSearchResults: [...cachedData, ...data],
        activeAbortController: null,
      })
    } catch (error) {
      // oportunity to send error to an error management slice
      set({
        productStoreError: error,
        loading: false,
        activeAbortController: null,
      })
    }
  },
})
