type Platform = 'android' | 'ios' | 'linux' | 'macos' | 'windows' | null

/**
 * Returns the platform the user's browser is currently running on:
 * - macos
 * - ios
 * - windows
 * - android
 * - linux
 */
function getPlatform(): Platform {
  const userAgent = window.navigator.userAgent.toLowerCase()

  if (/(macintosh|macintel|macppc|mac68k|macos)/i.test(userAgent)) {
    return 'macos'
  } else if (/(iphone|ipad|ipod)/i.test(userAgent)) {
    return 'ios'
  } else if (/(win32|win64|windows|wince)/i.test(userAgent)) {
    return 'windows'
  } else if (/android/.test(userAgent)) {
    return 'android'
  } else if (/linux/.test(userAgent)) {
    return 'linux'
  }

  return null
}

function platformIsMacOS(): boolean {
  return getPlatform() === 'macos'
}

function platformIsIOS(): boolean {
  return getPlatform() === 'ios'
}

function platformIsApple(): boolean {
  const platform = getPlatform()
  return platform === 'ios' || platform === 'macos'
}

function platformIsWindows(): boolean {
  return getPlatform() === 'windows'
}

function platformIsAndroid(): boolean {
  return getPlatform() === 'android'
}

function platformIsLinux(): boolean {
  return getPlatform() === 'linux'
}

export {
  getPlatform,
  platformIsAndroid,
  platformIsApple,
  platformIsIOS,
  platformIsLinux,
  platformIsMacOS,
  platformIsWindows,
}

export const getPlatforSpecificControlKeyString = () => {
  let controlKey = '\u2318'

  switch (getPlatform()) {
    case 'macos':
      controlKey = '\u2318'
      break
    case 'windows':
    default:
      controlKey = 'ctrl '
      break
  }
  return controlKey
}

export type { Platform }
