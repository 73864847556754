/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SpacerNext, TextNext as Text } from '@vori/gourmet-components'
import React from 'react'

import { getFullStorySessionURL } from '@vori/dashboard-integrations/FullStory/getFullStorySessionURL'

interface ErrorBoundaryState {
  errorMessage?: string
  hasError: boolean
  source?: Record<string, unknown> | string
}

interface ErrorBoundaryProps {
  children: React.ReactNode
  errorMessage?: string
  fallbackComponent?: React.ReactNode
}

interface ChildWithSource extends React.ReactElement {
  _source?: Record<string, unknown> | string
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    const childWithSource = this.props.children as ChildWithSource

    this.state = {
      hasError: false,
      errorMessage: '',
      ...(childWithSource &&
        childWithSource._source && {
          source: childWithSource._source,
        }),
    }
  }

  static getDerivedStateFromError(error: unknown): ErrorBoundaryState {
    // Update state so next render shows fallback UI.

    return { hasError: true, errorMessage: JSON.stringify(error) }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
    try {
      console.error(getFullStorySessionURL())
    } catch (error) {
      console.error('Error reporting failed:', error)
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallbackComponent) {
        return this.props.fallbackComponent
      }
      return (
        <>
          <Text size="text-sm" variant="negative">
            {this.props.errorMessage ?? 'Something went wrong.'}
          </Text>
          <SpacerNext />
        </>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
