import React, { lazy } from 'react'
import { Redirect, Switch } from 'react-router'

import { routes } from '@vori/dashboard-routes/auth'
import { SentryEnhancedRoute } from '@vori/dashboard-components'
import { sharedRoutes } from '@vori/dashboard-shared-routes'
import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'

import { LogoutPage } from './pages/logout'
import { useGlobalStore } from '../../state-management/store'

const LoginPage = lazy(() => import('./pages/login'))
const PasswordRecoveryPage = lazy(() => import('./pages/password-recovery'))
const VendorInvitationPage = lazy(() => import('./pages/vendor-invitation'))

const CreatePaymentProfilePage = lazy(
  () => import('../../components/payments/CreatePaymentProfile'),
)

function AuthRoutes(): JSX.Element {
  const { user } = useCurrentUserState()
  const setUserData = useGlobalStore((state) => state.user.setUserData)

  /*
  populate the global store with the user meatada
  TODO[cleanup, stability improvements]: We should move the user auth into this store over time.
  For now this is sufficient
  */
  if (setUserData) {
    setUserData(user)
  }

  if (user.state.isLoggedIn) {
    return (
      <SentryEnhancedRoute
        exact
        path={routes.logout.root.path}
        component={LogoutPage}
      />
    )
  }

  return (
    <Switch>
      <SentryEnhancedRoute
        exact
        path={routes.login.root.path}
        component={LoginPage}
      />
      <SentryEnhancedRoute
        exact
        path={routes.passwordRecovery.root.path}
        component={PasswordRecoveryPage}
      />
      <SentryEnhancedRoute
        exact
        path={routes.vendorInvitation.root.path}
        component={VendorInvitationPage}
      />
      {/**
       * @todo refactor components/payments -> pages/[user-role]/payments
       */}
      <SentryEnhancedRoute
        exact
        path={sharedRoutes().payments.createProfileFromJWT.path}
        component={CreatePaymentProfilePage}
      />
      <Redirect to={routes.login.root.path} />
    </Switch>
  )
}

export { AuthRoutes }
