import { StateCreator } from 'zustand'
import { ModalPropsFromHook } from '@vori/gourmet-components'

export type GlobalModalSlice = {
  opening: boolean
  closing: boolean
  isOpen: boolean
  isClosed: boolean
  showModalHeader: boolean
  fullBleedContent: boolean
  disableBackgroundBlur: boolean
  modalTitle: null | string
  modalProps: ModalPropsFromHook | null
  displayContent: React.ComponentType | JSX.Element | null
  setDisplayContent: (content: React.ComponentType | JSX.Element | null) => void
  toggleDisplay: (
    forceOpen?: boolean,
    shouldBeFullBleed?: boolean,
    shouldDisableBackgroundBlur?: boolean,
  ) => void
  setModalProps: (modalProps: ModalPropsFromHook) => void
  setAndDisplayContent: (
    content: React.ComponentType | JSX.Element,
    shouldBeFullBleed?: boolean,
    shouldDisableBackgroundBlur?: boolean,
  ) => void
  toggleFullBleedContent: (shouldBeFullBleed: boolean) => void
  toggleDisableBackgroundBlur: (shouldDisableBackgroundblur: boolean) => void
}

export const createGlobalModalSlice: StateCreator<GlobalModalSlice> = (
  set,
  get,
) => ({
  modalProps: null,
  opening: false,
  closing: false,
  isOpen: false,
  isClosed: true,
  fullBleedContent: false,
  disableBackgroundBlur: false,
  modalTitle: null,
  showModalHeader: false,
  displayContent: null,
  setModalProps: (modalProps) => {
    set({ modalProps })
  },
  toggleFullBleedContent: (shouldBeFullBleed = false) => {
    set({
      fullBleedContent: shouldBeFullBleed,
    })
  },
  toggleDisableBackgroundBlur: (shouldDisableBackgroundblur = false) => {
    set({
      disableBackgroundBlur: shouldDisableBackgroundblur,
    })
  },
  toggleDisplay: (
    forceOpen,
    shouldBeFullBleed = false,
    shouldDisableBackgroundBlur = false,
  ) => {
    const openState = forceOpen === undefined ? !get().isOpen : forceOpen

    set({
      isOpen: openState,
      isClosed: !openState,
      fullBleedContent: shouldBeFullBleed,
      disableBackgroundBlur: shouldDisableBackgroundBlur,
    })

    const modalControls = get().modalProps
    if (modalControls) {
      if (openState) {
        modalControls.show()
      } else {
        modalControls.close()
      }
    }
  },
  setDisplayContent: (content) => {
    if (!content) {
      throw new Error('A component or some element to display is required')
    }
    set({ displayContent: content })
  },
  setAndDisplayContent: (
    content,
    shouldBeFullBleed = false,
    shouldDisableBackgroundBlur = false,
  ) => {
    get().setDisplayContent(content)
    get().toggleDisplay(true, shouldBeFullBleed, shouldDisableBackgroundBlur)
  },
})
